<template>
  <div class="row justify-content-center" v-if="!fetching">
    <div class="candidate-profile col-sm-9">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h3 class="pb-2 text-primary mt-1">Edit Job</h3>
        <div class="w-100 d-flex justify-content-start sticky-header pt-3">
          <Stepper
            class="mb-3"
            :options="stepperOptions"
            :activeBlock="block"
            :goTo="slideTo"
          >
          </Stepper>
          <h6
            v-if="getDisplayJobID"
            class="pb-2 text-primary mt-1 font-weight-bold"
            style="white-space: nowrap"
            :style="'float: right'"
          >
            JOB ID: {{ getDisplayJobID}}
          </h6>
        </div>
      </div>
      <h6 v-if="getIsParentUser && isChildOrg" class="text-primary text-right">
        You are editing job for <strong>{{ getOrgName }}</strong>
      </h6>
      <span v-if="block == 0">
        <JobInfo
          :isJobSupplier="isJobSupplier"
          @step="gotoStep"
          @showHintOrgName="showHintOrgName = $event"
          @tempJob="tempJob = $event"
        />
      </span>
      <span v-if="block == 1">
        <JobAdditionalInfo @step="gotoStep" @tempJob="tempJob = $event" />
      </span>
      <span v-if="block == 2">
        <JobBenefits @step="gotoStep" @tempBenefits="tempBenefits = $event" />
      </span>
      <span v-if="block == 3">
        <JobTemplate
          @step="gotoStep"
          @tempSectionsContent="tempSectionsContent = $event"
          @getImageUrl="getImageUrl = $event"
        />
      </span>
      <span v-if="block == 4">
        <JobPreview @step="gotoStep" />
      </span>
    </div>
    <div :class="isMobile ? 'col-sm-9' : 'col-sm-3'" v-show="block != 4">
      <h6 class="text-right mt-2 preview-text">
        <CButton
          class="text-primary"
          color="link"
          style="text-decoration: none"
          @click="showModalPreview = true"
        >
          <em class="fas fa-eye"></em>
          Preview
        </CButton>
      </h6>
      <MicroPreview
        :jobDetails="jobById"
        :tempJob="tempJob"
        :tempSectionsContent="tempSectionsContent"
        :tempBenefits="tempBenefits"
        :getImageUrl="getImageUrl"
        @showModalPreview="
          !isMobile ? (showModalPreview = true) : (showModalPreview = false)
        "
        className="micro-preview"
      />
    </div>
    <CModal
      id="preview - Modal"
      title="Preview"
      color="primary"
      :show.sync="showModalPreview"
      size="lg"
    >
      <MicroPreview
        :jobDetails="jobById"
        :tempJob="tempJob"
        :tempSectionsContent="tempSectionsContent"
        :tempBenefits="tempBenefits"
        :getImageUrl="getImageUrl"
        className="macro-preview"
      />
      <template #footer>
        <div>
          <CButton color="secondary" @click="showModalPreview = false">
            Close
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import AddJob from "./AddJob.vue";
export default {
  extends: AddJob,
};
</script>
